webshop.SearchView = class {
  constructor(options) {
    Object.assign(this, options);
    this.preference = this.view_type;
    this.handleClickOnPriceFilter();
    this.handleClickOnRating();
    this.handleClickOnDate();
    this.handleLocationChange();

    loadFilters();
    const urlParams = new URLSearchParams(window.location.search);
    // // =========================================
    // const field_filters = JSON.parse(urlParams.get("field_filters"))        
    // const cityParam = field_filters.custom_city[0];
    // if(cityParam){
    //   document.getElementById("city").value = cityParam
    // }

    // const placeParam = field_filters.place[0];
    // if(placeParam){
    //   document.getElementById("place").value = placeParam
    // }
    // console.log(placeParam)
    // // =========================================
    const ratingParam = urlParams.get("rate");
    if (ratingParam !== null && ratingParam !== undefined) {
      this.make(false, ratingParam);
    } else {
      this.make();
    }
  }
  handleLocationChange() {
    let me = this
    const selectElement = document.getElementById('place');
    let placeSelected;

    // Add an event listener for the change event
    selectElement.addEventListener('change', function () {
      if (this.value == 'indoor') {
        placeSelected = { indoor: 1 }

      } else if (this.value == 'outdoor') {
        placeSelected = { outdoor: 1 }

      } else {
        placeSelected = { indoor: 1, outdoor: 1 }

      }
      frappe.call({
        method: "cityacts.api.get_item_groups",
        args: placeSelected,
        callback: function (r) {
          console.log("ddd", r.message[0]);
          let categoryCheckbox = document.getElementById("Category-ckeck");
          categoryCheckbox.innerHTML = ``
          if (r.message[0].data) {
            r.message[0].data.forEach((category) => {
              const divElement = document.createElement("div");
              divElement.classList.add("form-check");

              const labelElement = document.createElement("label");
              labelElement.classList.add("form-check-label");

              const inputElement = document.createElement("input");
              inputElement.setAttribute("type", "radio");
              inputElement.setAttribute("data-fieldname", "item_group"); // Ensure all radios have the same name
              inputElement.setAttribute("name", "item_group"); // Ensure all radios have the same name
              inputElement.setAttribute("value", category.name);
              if (frappe.boot.lang === "ar") {
                  inputElement.classList.add("float-left");
              } 
              inputElement.classList.add(
                "form-check-input",
                "custom-product-filter",
                "field-filter"
              );

              labelElement.innerText = category.name;

              labelElement.appendChild(inputElement);

              divElement.appendChild(labelElement);

              categoryCheckbox.appendChild(divElement);
            });
            me.bind_filters();

          }
        },
      });
      // Log the selected value to the console
      console.log(this.value);
    });

  }
  updateURL(param, option) {
    var currentURL = window.location.href;
    var newURL = currentURL.split("?")[0];
    var params = new URLSearchParams(window.location.search);

    if (option.trim() === "") {
      // If option is empty, remove the parameter from URL
      params.delete(param);
    } else {
      params.set(param, option);
    }

    newURL += "?" + params.toString();
    history.pushState({ path: newURL }, "", newURL);
  }
  handleClickOnRating() {
    const ratingBoxes = document.querySelectorAll(".ratingbox");

    ratingBoxes.forEach((box) => {
      box.addEventListener("click", () => {
        const number = box.querySelector("span").textContent;
        this.make(true, parseInt(number));
        let numberRate = parseInt(number);

        ratingBoxes.forEach((box) => {
          box.classList.remove("selected");
        });

        box.classList.add("selected");

        this.updateURL("rate", numberRate.toString());
      });
    });
  }
  handleClickOnDate() {
    let me = this;
    let dateSelect = document.getElementById("dateField");
    dateSelect.addEventListener("change", function () {
      const selectedDate = dateSelect.value;
      me.make(true), me.updateURL("dateField", selectedDate);
    });
  }

  async make(from_filters = false, rate = null) {
    this.products_section.empty();
    await this.get_user_location().catch((error) => {
      console.error("Continuing despite the error: ", error);
    });
    this.get_item_filter_data(from_filters, rate);
  }

  async get_user_location() {
    const me = this;
    return new Promise((resolve, reject) => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            me.user_lat = position.coords.latitude;
            me.user_lng = position.coords.longitude;

            console.log("get_user_location");
            console.log(me.user_lat + " , " + me.user_lng);
            resolve();
          },
          (error) => {
            console.error("Error Code = " + error.code + " - " + error.message);
            reject(error);
          },
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
          }
        );
      } else {
        console.log("Geolocation is not supported by this browser.");
        reject(new Error("Geolocation is not supported by this browser."));
      }
    });
  }

  handleClickOnPriceFilter() {
    let me = this;
    let cleanOutputOne;
    let cleanOutputTwo;

    const rangeOne = document.querySelector('input[name="rangeOne"]');
    const rangeTwo = document.querySelector('input[name="rangeTwo"]');

    const outputOne = document.querySelector(".outputOne").textContent;
    
    rangeOne.addEventListener("input", function () {
      const outputOne = document.querySelector(".outputOne").textContent;
      cleanOutputOne = outputOne.replace(/\$/g, "");

      
    });

    rangeTwo.addEventListener("input", function () {
      const outputTwo = document.querySelector(".outputTwo").textContent;
      cleanOutputTwo = outputTwo.replace(/\$/g, "");

      
    });

    let priceButton = document.getElementById("pricefilter");
    priceButton.addEventListener("click", () => {
    me.updateURL("min", cleanOutputOne);
    me.updateURL("max", cleanOutputTwo);
    this.make(true);
    }
     );
  }

  get_item_filter_data(from_filters = false, rate = 0) {
    const urlParams = new URLSearchParams(window.location.search);
    let minValue;

    const minParam = urlParams.get("min");
    console.log("minParam", minParam)
    if (minParam !== null && minParam !== undefined) {
      minValue = parseInt(minParam);
      console.log("minValue", minValue)
    } else {
      minValue = 0;
    }
    let maxValue;

    const maxParam = urlParams.get("max");
    console.log("maxParam", maxParam)

    if (maxParam !== null && maxParam !== undefined) {
      maxValue = parseInt(maxParam);
    } else {
      maxValue = 1200;
    }

    // 	var min = document.querySelector('.outputOne');

    //  var textContent = min.textContent;

    //  var minValue = parseInt(textContent.replace(/\D/g, ''));
    //  var max = document.querySelector('.outputTwo');

    //  var textContent = max.textContent;

    //  var maxValue = parseInt(textContent.replace(/\D/g, ''));

    let args = this.get_query_filters();
    console.log("aaa", args)

    const ratingBoxes = document.querySelectorAll(".ratingbox");

    let selectedNumber = 0;

    ratingBoxes.forEach((box, index) => {
      if (box.classList.contains("selected")) {
        selectedNumber = index;
      }
    });

    if (rate != null) {
      selectedNumber = rate;
    }
    let argsBody = {
      query_args: args,
      min: minValue,
      max: maxValue,
      avg_rating: parseInt(selectedNumber),
      user_lat: this.user_lat,
      user_lng: this.user_lng,
    };
    // let dateSelect = document.getElementById("dateField");
    // const selectedDate = dateSelect.value;
    // if (selectedDate) {
    //   argsBody.target_date = selectedDate;
    // }

    //===========================
    const dateField = new URLSearchParams(window.location.search).get(
      "dateField"
    );
    if (dateField) {
      argsBody.target_date = dateField;
    }
    //===========================

    let me = this;
    frappe.call({
      method: "cityacts.api.get_product_filter_data",
      args: argsBody,
      callback: function (result) {
        if (!result || result.exc || !result.message || result.message.exc) {
          //me.render_no_products_section(true);
        } else {
          // Sub Category results are independent of Items
          // if (me.item_group && result.message["sub_categories"].length) {
          // 	me.render_item_sub_categories(result.message["sub_categories"]);
          // }

          if (!result.message["items"].length) {
            // if result has no items or result is empty
            //me.render_no_products_section();
          } else {
            // Add discount filters
            //me.re_render_discount_filters(result.message["filters"].discount_filters);

            // Render views
            console.log("||||||||||||||");
            console.log(result.message["items"]);
            me.render_list_view(
              result.message["items"],
              result.message["settings"]
            );

            me.products = result.message["items"];
            me.product_count = result.message["items_count"];
          }

          // Bind filter actions
          if (!from_filters) {
            // If `get_product_filter_data` was triggered after checking a filter,
            // don't touch filters unnecessarily, only data must change
            // filter persistence is handle on filter change event
            me.bind_filters();
            //me.restore_filters_state();
          }

          // Bottom paging
          //me.add_paging_section(result.message["settings"]);
        }

        //me.disable_view_toggler(false);
      },
    });
  }

  render_list_view(items, settings) {
    let me = this;
    this.prepare_product_area_wrapper(items);

    // new webshop.ProductList({
    // 	items: items,
    // 	products_section: $("#products-list-area"),
    // 	settings: settings,
    // 	preference: me.preference
    // });
  }

  prepare_product_area_wrapper(items) {
    for (let item of items) {
      const favoriteFillColor = item.is_favourite ? 'red' : 'none';

      this.products_section.append(
        `
            <div class="card" >
                <div class="image-card" style="max-width: 300px; max-height: 300px; border-radius: 10px" height="300" width="300">
                    <img src='` +
        item.website_image +
        `' style="width: 300px; height: 300px">
                </div>
                <div class="details-card" style="">
                    <div class="header-card">
                        <p class="paragraph-header-card" item-code="${item.item_code}" style="cursor:pointer;">${item.web_item_name
        }</p>
                        <!--<span>$240/<span style="font-size: 14px;">night</span></span>-->
                    </div>
                    <div class="location-card" style="display:flex; align-items:end">
                      <a href="https://www.google.com/maps/place/${item.custom_latitude
        },${item.custom_longitude}" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" style="margin-right:4px" width="25"
                                height="25" fill="black" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                <path
                                    d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                            </svg>
                      </a> 
                      <span>${item.distance
          ? Math.floor(item.distance * 100) / 100 + " Km"
          : translations.unavailable
        }
                      </span>
                    </div>


					<div class="rating">     
          <div class = "avg">${translations.rating} : ${item.avg_rating}</div>      


						<input type="radio"  value="5"  ${item.avg_rating >= 5 ? "checked" : ""
        }><label for="5">☆</label>
						<input type="radio"  value="4"  ${item.avg_rating >= 4 ? "checked" : ""
        }><label for="4">☆</label>
						<input type="radio"  value="3"  ${item.avg_rating >= 3 ? "checked" : ""
        }><label for="3">☆</label>
						<input type="radio"  value="2"  ${item.avg_rating >= 2 ? "checked" : ""
        }><label for="2">☆</label>
						<input type="radio"  value="1"  ${item.avg_rating >= 1 ? "checked" : ""
        }><label for="1">☆ </label> 
                	</div>
						  



						  
                    <hr style="color: #112211;
            width:100%;opacity: 25%;height: 0.5px;margin-top:24px;margin-bottom: 24px;">
                    <div class="Booking-card">
                        <button class = "favourite_button" item-code="${item.item_code}" style="margin-right: 10px;">
                            <svg width="18" height="17" viewBox="0 0 18 17" fill="${favoriteFillColor}" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.7863 1.625C10.2504 1.625 9.0004 4.125 9.0004 4.125C9.0004 4.125 7.7504 1.625 5.21446 1.625C3.15352 1.625 1.52149 3.34922 1.5004 5.40664C1.45743 9.67734 4.88829 12.7145 8.64884 15.2668C8.75251 15.3373 8.87501 15.3751 9.0004 15.3751C9.12579 15.3751 9.24829 15.3373 9.35196 15.2668C13.1121 12.7145 16.543 9.67734 16.5004 5.40664C16.4793 3.34922 14.8473 1.625 12.7863 1.625V1.625Z" stroke="#4C4850" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                        
                        </button>
                        <a class="book-link-button" item-code="${item.item_code}">${translations.Book}</a>
                    </div>
                </div>
            </div>
            `
      );
    }
    const favouriteButtons = document.querySelectorAll('.favourite_button');
    favouriteButtons.forEach(button => {
      button.addEventListener('click', function () {
        const itemCode = this.getAttribute('item-code');
        const svgElement = button.querySelector('svg');
        const fillColor = svgElement.getAttribute('fill');


        if (fillColor === 'red') {
          console.log('Removing favorite item');
          svgElement.setAttribute('fill', 'none');
          frappe.call({
            method: "cityacts.api.remove_favorite_item",
            args: { item_code: itemCode },
            callback: function (r) {
              console.log(r.message);
            },
            statusCode: {
              401: function () {
                console.log("Handling 401 Unauthorized");
                window.location.href = '/login';
                return false
              },
              403: function () {
                console.log("Handling 403 Forbidden");
                window.location.href = '/login';
                return false
              },
              301: function () {
                console.log("Handling 403 Forbidden");
                window.location.href = '/login';
                return false
              }
            }
          });
        } else {
          console.log('Adding favorite item');
          svgElement.setAttribute('fill', 'red');
          frappe.call({
            method: "cityacts.api.add_item_to_favorites",
            args: { item_code: itemCode },
            callback: function (r) {
              console.log(r.message);
            },
            statusCode: {
              401: function () {
                console.log("Handling 401 Unauthorized");
                window.location.href = '/login';
                return false
              },
              403: function () {
                console.log("Handling 403 Forbidden");
                window.location.href = '/login';
                return false
              },
              301: function () {
                console.log("Handling 403 Forbidden");
                window.location.href = '/login';
                return false
              }
            }
          });
        }
      });
    });






    const bookButtons = document.querySelectorAll('.book-link-button');
    bookButtons.forEach(button => {
      button.addEventListener('click', function () {
        const itemCode = this.getAttribute('item-code');
        console.log(itemCode);
        window.open("/pages/details?item=" + encodeURIComponent(itemCode), "_blank");
      });
    });
    const nameOfEvent = document.querySelectorAll('.paragraph-header-card');
    nameOfEvent.forEach(eve => {
      eve.addEventListener('click', function () {
        const itemCode = this.getAttribute('item-code');
        console.log(itemCode);
        window.open("/pages/details?item=" + encodeURIComponent(itemCode), "_blank");
      });
    });



  }
  bind_filters() {
    console.log("kk")
    let me = this;
    this.field_filters = {};
    // =======================================================
    var params = new URLSearchParams(window.location.search);
    if (JSON.parse(params.get("field_filters"))) {
      this.field_filters = JSON.parse(params.get("field_filters"));
    }
    // =======================================================
    $(".custom-product-filter").off("change").on("change", (e) => {
      const selectedValue = $(e.target);
      me.from_filters = true;

      const $option = $(e.target);
      const filter_value = $option.val();
      const filter_name = $option.attr("data-fieldname");
      // Ensure field_filters is an object
      if (typeof this.field_filters === 'string') {
        try {
          this.field_filters = JSON.parse(this.field_filters);
        } catch (error) {
          console.error('Failed to parse field_filters:', error);
          this.field_filters = {};
        }
      }

      if ($option.is(".field-filter")) {
        if (filter_value) {
          delete this.field_filters[filter_name];
          this.field_filters[filter_name] =
            this.field_filters[filter_name] || [];
          if (!in_list(this.field_filters[filter_name], filter_value)) {
            this.field_filters[filter_name].push(filter_value);
          }
        } else {
          delete this.field_filters[filter_name];
          this.field_filters[filter_name] =
            this.field_filters[filter_name] || [];
          this.field_filters[filter_name] = this.field_filters[
            filter_name
          ].filter((v) => v !== filter_value);
        }

        if (this.field_filters[filter_name].length === 0) {
          delete this.field_filters[filter_name];
        }
      }

      if (filter_name === "item_group") {
        const checkedValues = $(
          '.custom-product-filter[data-fieldname="item_group"]:checked'
        )
          .map(function () {
            return $(this).val();
          })
          .get();

        this.field_filters[filter_name] = checkedValues;
      }

      me.change_route_with_filters();
    });

    // bind filter lookup input box
    // $('.filter-lookup-input').on('keydown', frappe.utils.debounce((e) => {
    // 	const $input = $(e.target);
    // 	console.log("$input",$input)
    // 	const keyword = ($input.val() || '').toLowerCase();
    // 	console.log("keyword",keyword)
    // 	const $filter_options = $input.next('.filter-options');
    // 	console.log("filter_options",$filter_options)

    // 	$filter_options.find('.filter-lookup-wrapper').show();
    // 	$filter_options.find('.filter-lookup-wrapper').each((i, el) => {
    // 		const $el = $(el);
    // 		console.log($el)
    // 		const value = $el.data('value').toLowerCase();
    // 		console.log("value", value)
    // 		if (!value.includes(keyword)) {
    // 			$el.hide();
    // 		}
    // 	});
    // }, 300));
  }
  change_route_with_filters() {
    let route_params = frappe.utils.get_query_params();
    // Extract rate if it exists and ensure it is persisted
    const rate = route_params.rate;
    const dateField = route_params.dateField;
    const min = route_params.min;
    const max = route_params.max;

    let start = this.if_key_exists(route_params.start) || 0;
    if (this.from_filters) {
      start = 0; // Show items from first page if new filters are triggered
    }

    // Include rate in the query string if it exists
    const query_string = this.get_query_string({
      start: start,
      field_filters: JSON.stringify(this.if_key_exists(this.field_filters)),
      rate: rate,
      dateField: dateField,
      min: min,
      max: max
    });

    window.history.pushState(
      "filters",
      "",
      `${location.pathname}?` + query_string
    );

    this.make(true);
  }

  if_key_exists(obj) {
    let exists = false;
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key]) {
        exists = true;
        break;
      }
    }
    return exists ? obj : undefined;
  }
  get_query_string(object) {
    const url = new URLSearchParams();
    for (let key in object) {
      const value = object[key];
      if (value !== undefined && value !== null) {
        // Ensure that undefined or null values are not appended
        url.append(key, value);
      }
    }
    return url.toString();
  }
  get_query_string(object) {
    const url = new URLSearchParams();
    for (let key in object) {
      const value = object[key];
      if (value !== undefined && value !== null) {
        // Ensure that undefined or null values are not appended
        url.append(key, value);
      }
    }
    return url.toString();
  }

  get_query_filters() {
    const filters = frappe.utils.get_query_params();
    console.log("Received URL parameters:", filters);

    let { field_filters } = filters;



    // Checking and parsing field_filters if it is a doubly-encoded JSON string
    try {
      // Decode potentially double-encoded JSON string
      if (typeof field_filters === 'string') {
        field_filters = JSON.parse(field_filters); // First parse to remove outer quotes and escape characters
        console.log("Field filters after first parse:", field_filters);
        if (typeof field_filters === 'string') {
          field_filters = JSON.parse(field_filters); // Second parse to convert to JSON object
        }
      } else {
        field_filters = field_filters || {};
      }
    } catch (error) {

      field_filters = {};
    }



    return {
      field_filters: field_filters,
      start: filters.start || null,
      from_filters: this.from_filters || false,
    };
  }


};

function loadFilters() {
  return new Promise((resolve, reject) => {
    frappe.call({
      method: "cityacts.api.get_field_filters",
      args: {},
      callback: function (r) {
        console.log('|||get_field_filters|||')
        if (r.message) {

          r.message[0].data.forEach((result) => {
            let search_div = document.getElementById("search_row");
            frappe.boot.lang == "ar"? search_div.dir = "rtl" : "ltr";
            let cities = result.custom_city
            let citySelect = document.getElementById("city");
            if (cities && citySelect) {
              cities.forEach((city) => {
                const option = document.createElement("option");
                option.value = city.name;
                if (frappe.boot.lang == "ar"){
                  option.textContent = city.custom_name_ar
                }
                else {
                option.textContent = city.name;
                }
                citySelect.appendChild(option);
              })
            }

            let locations = result.custom_place;
            let place = document.getElementById("place");
            console.log("abd3",locations)
            if (locations && place) {
              locations.forEach((loc) => {
                const option = document.createElement("option");
                option.value = loc.name;
                if (frappe.boot.lang == "ar"){
                  option.textContent = loc.custom_title_ar
                }
                else {
                option.textContent = loc.name;
                }
                place.appendChild(option);
              });
            }

            let item_groups = result.item_group
            let categoryCheckbox = document.getElementById("Category-ckeck");search_row
            
            if (item_groups) {
              item_groups.forEach((category) => {
                const divElement = document.createElement("div");
                divElement.classList.add("form-check");

                const labelElement = document.createElement("label");
                labelElement.classList.add("form-check-label");

                const inputElement = document.createElement("input");
                inputElement.setAttribute("type", "radio");
                inputElement.setAttribute("data-fieldname", "item_group"); // Ensure all radios have the same name
                inputElement.setAttribute("name", "item_group"); // Ensure all radios have the same name
                inputElement.setAttribute("value", category.name);
                if (frappe.boot.lang === "ar") {
                  inputElement.classList.add("float-left");
                }
                inputElement.classList.add(
                  "form-check-input",
                  "custom-product-filter",
                  "field-filter"
                );
                if (frappe.boot.lang == "ar"){
                  labelElement.innerText = category.custom_item_group_name_ar;
                }
                else{
                labelElement.innerText = category.name;
                }
                labelElement.appendChild(inputElement);

                divElement.appendChild(labelElement);

                categoryCheckbox.appendChild(divElement);
              });
            }
          })

          // const cities = r.message[0].data.item.custom_city;
          // console.log(cities)
          // const citySelect = document.getElementById("city");          
          // if (cities && citySelect) {
          //   cities.forEach((city) => {
          //     console.log('********')
          //     console.log(city)
          //     const option = document.createElement("option");
          //     option.value = city.name;
          //     option.textContent = city.name;
          //     citySelect.appendChild(option);
          //   });
          // }

          // const item_groups = r.message[0].data.find(
          //   (item) => item.item_group
          // )?.item_group;
          // const categoryCheckbox = document.getElementById("Category-ckeck");
          // if (item_groups) {
          //   item_groups.forEach((category) => {
          //     const divElement = document.createElement("div");
          //     divElement.classList.add("form-check");

          //     const labelElement = document.createElement("label");
          //     labelElement.classList.add("form-check-label");

          //     const inputElement = document.createElement("input");
          //     inputElement.setAttribute("type", "radio");
          //     inputElement.setAttribute("data-fieldname", "item_group"); // Ensure all radios have the same name
          //     inputElement.setAttribute("name", "item_group"); // Ensure all radios have the same name
          //     inputElement.setAttribute("value", category.name);
          //     inputElement.classList.add(
          //       "form-check-input",
          //       "custom-product-filter",
          //       "field-filter"
          //     );

          //     labelElement.innerText = category.name;

          //     labelElement.appendChild(inputElement);

          //     divElement.appendChild(labelElement);

          //     categoryCheckbox.appendChild(divElement);
          //   });
          // }
          // const locations = r.message[0].data.find((item) => item.place)?.place;
          // const place = document.getElementById("place");
          // if (locations && place) {
          //   locations.forEach((loc) => {
          //     const option = document.createElement("option");
          //     option.value = loc;
          //     option.textContent = loc;
          //     place.appendChild(option);
          //   });
          // }
          //  loadElementsFromUrl()
          resolve();
          // =========================================

          const urlParams = new URLSearchParams(window.location.search);
          const fieldFiltersParam = urlParams.get("field_filters");

          console.log("Raw field_filters:", fieldFiltersParam);

          if (fieldFiltersParam) {
            try {
              let cleanedJson = fieldFiltersParam.trim();
              if (cleanedJson.startsWith('"') && cleanedJson.endsWith('"')) {
                cleanedJson = cleanedJson.substring(1, cleanedJson.length - 1);
              }
              cleanedJson = decodeURIComponent(cleanedJson.replace(/\\\"/g, "\""));
              console.log("Cleaned JSON:", cleanedJson);

              const fieldFilters = JSON.parse(cleanedJson);

              if (fieldFilters.custom_city && fieldFilters.custom_city.length > 0) {
                const cityParam = fieldFilters.custom_city[0];
                console.log("cityParam", cityParam);
                document.getElementById("city").value = cityParam;
              }
              if (fieldFilters.custom_place && fieldFilters.custom_place.length > 0) {
                const placeParam = fieldFilters.custom_place[0];
                document.getElementById("place").value = placeParam.toLowerCase();
              }
              if (fieldFilters.item_group && fieldFilters.item_group.length > 0) {
                const itemGroupParam = fieldFilters.item_group[0];
                console.log("abd4",itemGroupParam)
                $("#Category-ckeck").find(`[value=${itemGroupParam}]`).attr("checked", true);
              }
            } catch (error) {
              console.error("Error parsing field_filters:", error);
            }
          }

          else {
            console.log("poopopo")
          }

          const dateFieldParam = urlParams.get("dateField")
          if (dateFieldParam) {
            document.getElementById("dateField").value = dateFieldParam
          }
          // const ratingParam = urlParams.get("rate");
          // if (ratingParam !== null && ratingParam !== undefined) {
          //   this.make(false, ratingParam);
          // } else {
          //   this.make();
          // }
        }
      },
    });
  });
}
